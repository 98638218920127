<template>
  <div class="info" onselectstart="javascript:return false;">
    <div class="p-tabs" v-if="!checkFile && !checkCell">
      <div :class="{ active: projectFocus, 'p-tabs__item': true }" @click="vChange(0)">{{ $t('messages.project') }}
      </div>
      <div :class="{ active: clFocus, 'p-tabs__item': true }" @click="vChange(1)">{{ $t('messages.ComponentLibrary') }}
      </div>
      <div :class="{ active: symbolFocus, 'p-tabs__item': true }" @click="vChange(2)">{{ $t('messages.SymbolLibrary') }}
      </div>
    </div>
    <div class="p-body" v-if="projectFocus">
      <div class="check-item" v-if="checkProject || checkFile || checkCell">
        <div class="check-item__title">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item @click.native="toPersonal" style="cursor: pointer">{{ $t('messages.personalCenter') }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="checkFile" :class="{ highlight: checkProject }">{{ currentProject }}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="checkCell" :class="{ highlight: checkFile }">{{ currentFile }}</el-breadcrumb-item>
            <!-- <el-breadcrumb-item v-if="checkCell" :class="{highlight: checkCell}">{{currentCell}}</el-breadcrumb-item> -->
          </el-breadcrumb>
        </div>
        <div class="check-item__header__info" v-if="checkFile">
          <div style="font-size: 22px; font-weight: bold; color: #333333">{{ currentProject }}</div>
        </div>
        <div class="check-item__header__info" v-if="checkCell">
          <div style="font-size: 22px; font-weight: bold; color: #333333">{{ currentFile }}</div>
        </div>
      </div>
      <div v-if="!checkFile && !checkCell && !checkProject">
        <div class="p-item" v-if="!selectShare && !selectCopy && !selectDelete">
          <button class="main-btn default" @click="selectCopy = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/复制到.svg" />
            {{ $t('messages.batchCopyTo') }}
          </button>
          <button class="main-btn default" @click="selectShare = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/分享.svg" />
            {{ $t('messages.batchShare') }}
          </button>
          <button class="main-btn danger" @click="selectDelete = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
            {{ $t('messages.batchDelete') }}
          </button>
          <input class="main-input" @keyup.enter="searchItemName" v-model="itemName" :placeholder="$t('messages.pleaseInputName')" />
          <button class="main-btn fill" @click="searchItemName">{{ $t('messages.search') }}</button>
          <button class="main-btn fill" @click="receiveShareVisible = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/接收分享.svg" />
            {{ $t('messages.receiveShare') }}
          </button>
        </div>
        <div class="p-item" v-if="selectShare">
          <button class="main-btn default" @click="toShare">{{ $t('messages.toShare') }}</button>
          <button class="main-btn default" @click=";(selectShare = false), resetSelection('personalTable')">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="p-item" v-if="selectCopy">
          <button class="main-btn default" @click="toCopy">{{ $t('messages.copy') }}</button>
          <button class="main-btn default" @click=";(selectCopy = false), resetSelection('personalTable')">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="p-item" v-if="selectDelete">
          <button class="main-btn default" @click="toDelete">{{ $t('messages.delete') }}</button>
          <button class="main-btn default" @click=";(selectDelete = false), resetSelection('personalTable')">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="p-table">
          <el-table ref="personalTable" v-loading="loading" :data="personalTableData" height="80vh" header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" v-if="selectShare || selectCopy || selectDelete" width="100" align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.fileName')" prop="name" align="center">
              <template slot-scope="scope">
                <div style="cursor: pointer; color: #0c7de6" @click="checkFiles(scope.row)">
                  <span class="iconfont icon-xiangmuming"></span>
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.updateTime')" prop="create_time" align="center" width="300"></el-table-column>
          </el-table>
          <el-pagination background class="qeda-page" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>
      <div v-if="checkFile">
        <div class="p-item" v-if="!selectShare && !selectCopy && !selectDelete">
          <button class="main-btn default" @click="selectCopy = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/复制到.svg" />
            {{ $t('messages.batchCopyTo') }}
          </button>
          <button class="main-btn default" @click="selectShare = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/分享.svg" />
            {{ $t('messages.batchShare') }}
          </button>
          <button class="main-btn danger" @click="selectDelete = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
            {{ $t('messages.batchDelete') }}
          </button>
          <input class="main-input" @keyup.enter="searchFileName" v-model="fileSearchName" :placeholder="$t('messages.pleaseInputName')" />
          <button class="main-btn fill" @click="searchFileName">{{ $t('messages.search') }}</button>
          <button class="main-btn fill" @click="receiveFileShareVisible = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/接收分享.svg" />
            {{ $t('messages.receiveShare') }}
          </button>
        </div>
        <div class="p-item" v-if="selectShare">
          <button class="main-btn default" @click="toFileShare">{{ $t('messages.toShare') }}</button>
          <button class="main-btn default" @click=";(selectShare = false), resetSelection('personalFileTable')">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="p-item" v-if="selectCopy">
          <button class="main-btn default" @click="toFileCopy">{{ $t('messages.copy') }}</button>
          <button class="main-btn default" @click=";(selectCopy = false), resetSelection('personalFileTable')">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="p-item" v-if="selectDelete">
          <button class="main-btn default" @click="toFileDelete">{{ $t('messages.delete') }}</button>
          <button class="main-btn default" @click=";(selectDelete = false), resetSelection('personalFileTable')">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="p-table">
          <el-table ref="personalFileTable" v-loading="loading" :data="personalFileTableData" height="70vh" header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" v-if="selectShare || selectCopy || selectDelete" width="100" align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (fileCurrentPage - 1) * filePageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.fileName')" prop="name" align="center">
              <template slot-scope="scope">
                <div style="cursor: pointer; color: #0c7de6" @click="checkCells(scope.row)">
                  <span style="position: relative; top: 2px" class="iconfont icon-yuanlituwenjianmingcheng" v-if="scope.row.types == 3"></span>
                  <span style="position: relative; top: 2px" class="iconfont icon-bantuwenjian" v-else></span>
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.updateTime')" prop="create_time" align="center" width="300"></el-table-column>
          </el-table>
          <el-pagination background class="qeda-page" @size-change="fileSizeChange" @current-change="fileCurrentChange" :current-page="fileCurrentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="filePageSize" layout="total, sizes, prev, pager, next, jumper" :total="fileTotal"></el-pagination>
        </div>
      </div>
      <div v-if="checkCell">
        <div class="p-item" v-if="!selectShare && !selectCopy && !selectDelete">
          <button class="main-btn danger" @click="selectDelete = true">
            <img alt="" class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
            {{ $t('messages.batchDelete') }}
          </button>
          <input class="main-input" @keyup.enter="searchCellName" v-model="cellSearchName" :placeholder="$t('messages.pleaseInputName')" />
          <button class="main-btn fill" @click="searchCellName">{{ $t('messages.search') }}</button>
        </div>
        <div class="p-item" v-if="selectDelete">
          <button class="main-btn default" @click="toCellDelete">{{ $t('messages.delete') }}</button>
          <button class="main-btn default" @click=";(selectDelete = false), resetSelection('personalCellTable')">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="p-table">
          <el-table ref="personalCellTable" v-loading="loading" :data="personalCellTableData" height="70vh" header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" v-if="selectShare || selectCopy || selectDelete" width="100" align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (cellCurrentPage - 1) * cellPageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.fileName')" prop="name" align="center">
              <template slot-scope="scope">
                <div style="cursor: pointer; color: #0c7de6">
                  <span style="position: relative; top: 2px" class="iconfont icon-yuanlitu" v-if="scope.row.types == 3"></span>
                  <span style="position: relative; top: 2px" class="iconfont icon-qijianming" v-else-if="scope.row.types == 2"></span>
                  <span style="position: relative; top: 2px" class="iconfont icon-dakaifuhao" v-else-if="scope.row.types == 4"></span>
                  <span style="position: relative; top: 2px" class="iconfont icon-dakaifuhao" v-else-if="scope.row.types == 5"></span>
                  <span style="position: relative; top: 2px" class="iconfont icon-bantuming" v-else></span>
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.updateTime')" prop="create_time" align="center" width="300"></el-table-column>
          </el-table>
          <el-pagination background class="qeda-page" @size-change="cellSizeChange" @current-change="cellCurrentChange" :current-page="cellCurrentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="cellPageSize" layout="total, sizes, prev, pager, next, jumper" :total="cellTotal"></el-pagination>
        </div>
      </div>
    </div>
    <div class="p-body device-flex" v-if="clFocus">
      <div class="device-select">
        <div class="device-select__header">
          <div class="device-select__header--text">{{$t('messages.ComponentLibrary')}}</div>
          <div class="device-select__header--btn" @click="batchExportLibraryVisible = true" v-show="false">
            <span class="iconfont icon-daochu"></span>
            {{$t('messages.exportLibrary')}}
          </div>
        </div>
        <div class="device-select__btnGroup" v-if="!checkLibraryDelete">
          <el-button type="sure" size="mini" @click="createNewLibraryVisible = true">
            <span class="icon el-icon-plus"></span>
            {{$t('messages.createNewComponentLibrary')}}
          </el-button>
          <el-button type="delete" size="mini" @click="checkLibraryDelete = true">
            <span class="icon el-icon-delete"></span>
            {{$t('messages.deleteComponentLibrary')}}
          </el-button>
        </div>
        <div class="device-select__btnGroup" v-else>
          <el-button type="del-sure" size="mini" @click="toDeleteLibrary">
            {{$t('messages.sure')}}
          </el-button>
          <el-button type="del-cancel" size="mini" @click="cancelCheckLibraryDelete">
            {{$t('messages.cancel')}}
          </el-button>
        </div>
        <div class="device-select__list">
          <div @click="selectDeviceChange(i, k)" :class="{ 'device-select__list--item': true, checked: select_component_index == k }" v-for="(i, k) in libraryList" :key="k">
            <div class="list--item__text">{{ i.name }}</div>
            <div class="list--item__check" v-if="i.snow_id !== '1' && checkLibraryDelete">
              <el-checkbox v-model="i.checked"></el-checkbox>
            </div>
            <div class="checked-border" v-show="select_component_index == k"></div>
          </div>
        </div>
      </div>
      <div class="device-info">
        <div class="device-info__header" v-if="select_component_obj">
          <div class="device-info__header--text">{{ select_component_obj.name }}</div>
          <div class="device-info__header--btn" v-if="select_component_obj.snow_id !== '1'" @click="updateLibraryVisible = true">
            <span class="iconfont el-icon-edit"></span>
            {{$t('messages.updataLibraryName')}}
          </div>
        </div>
        <div class="p-item" v-if="!selectDeviceExport && !selectDeviceDelete">
          <button @click="batchAddComponentVisible = true" class="main-btn fill">
            <!-- <img class="btn-icon" src="../../assets/icons/personnel/导出.svg" /> -->
            <span class="btn-iconfont iconfont icon-Pell_tianjia"></span>
            <!-- 批量添加器件 -->
            {{$t('messages.batchAddComponent')}}
          </button>
          <button @click="selectDeviceExport = true" class="main-btn default">
            <img class="btn-icon" src="../../assets/icons/personnel/导出.svg" />
            {{ $t('messages.batchExportGDS') }}
          </button>
          <button @click="selectDeviceDelete = true" class="main-btn danger">
            <img class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
            {{ $t('messages.batchDelete') }}
          </button>
          <el-select class="componentSelect" size="mini" v-model="select_component_type">
            <el-option v-for="item in $t('messages.componentOptions')" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <input class="main-input" v-model.trim="searchDevice" :placeholder="$t('messages.placeholder_1')" />
          <button class="main-btn fill" @click="searchDeviceClick">{{ $t('messages.search') }}</button>
        </div>
        <div class="p-item" v-if="selectDeviceExport">
          <button class="main-btn default" @click="toExportDevice">{{ $t('messages.batchExportGDS') }}</button>
          <button class="main-btn default" @click=";(selectDeviceExport = false), resetSelection('personalDeviceTable')">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="p-item" v-if="selectDeviceDelete">
          <button class="main-btn default" @click="toDeleteDevice">{{ $t('messages.delete') }}</button>
          <button class="main-btn default" @click=";(selectDeviceDelete = false), resetSelection('personalDeviceTable')">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="p-table-device">
          <el-table v-loading="loading" ref="personalDeviceTable" :data="personalDeviceTableData" width="100%" height="100%" header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" v-if="selectDeviceExport || selectDeviceDelete" width="100" align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.ComponentName')" prop="name" align="center"></el-table-column>
            <el-table-column :label="$t('messages.ComponentType')" prop="cell_component_type" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.cell_component_type == 1">{{ $t('messages.fixedDevices') }}</div>
                <div v-else>{{ $t('messages.parameterizedDevice') }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.uploadStatus')" prop="is_public" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.is_public == 0">{{ $t('messages.notUpload') }}</div>
                <div v-else>{{ $t('messages.alreadyUpload') }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.uploadTime')" prop="public_time" align="center">
              <template slot-scope="scope">
                <div v-if="!scope.row.public_time">---</div>
                <div v-else>{{ scope.row.public_time }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.operation')" align="center">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="upload" placement="right">
                  <img v-if="scope.row.is_public == 0" @click="toUploadDevice(scope.row)" class="operateIcon" src="../../assets/icons/devices/上传到器件库.svg" />
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="update" placement="right">
                  <img v-if="scope.row.is_public == 1 && scope.row.is_update == 1" @click="toUploadDevice(scope.row)" class="operateIcon" src="../../assets/icons/devices/更新到器件库.svg" />
                </el-tooltip>
                <img v-if="scope.row.is_public == 1 && scope.row.is_update == 0" class="operateIcon disabled" src="../../assets/icons/devices/更新到器件库2.svg" />
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>
    </div>
    <div class="p-body device-flex" v-if="symbolFocus">
      <div class="device-select">
        <div class="device-select__header">
          <div class="device-select__header--text">{{$t('messages.symbolLibrary')}}</div>
          <div class="device-select__header--btn" @click="batchExportLibraryVisible = true" v-show="false">
            <span class="iconfont icon-daochu"></span>
            {{$t('messages.exportLibrary')}}
          </div>
        </div>
        <div class="device-select__btnGroup" v-if="!checkLibraryDelete">
          <el-button type="sure" size="mini" @click="createNewLibraryVisible = true">
            <span class="icon el-icon-plus"></span>
            {{$t('messages.createNewSymbolLibrary')}}
          </el-button>
          <el-button type="delete" size="mini" @click="checkLibraryDelete = true">
            <span class="icon el-icon-delete"></span>
            {{$t('messages.deleteSymbolLibrary')}}
          </el-button>
        </div>
        <div class="device-select__btnGroup" v-else>
          <el-button type="del-sure" size="mini" @click="toDeleteLibrary">
            {{$t('messages.sure')}}
          </el-button>
          <el-button type="del-cancel" size="mini" @click="cancelCheckLibraryDelete">
            {{$t('messages.cancel')}}
          </el-button>
        </div>
        <div class="device-select__list">
          <div @click="selectDeviceChange(i, k)" :class="{ 'device-select__list--item': true, checked: select_component_index == k }" v-for="(i, k) in libraryList" :key="k">
            <div class="list--item__text">{{ i.name }}</div>
            <div class="list--item__check" v-if="i.snow_id !== '1' && checkLibraryDelete">
              <el-checkbox v-model="i.checked"></el-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="device-info">
        <div class="device-info__header" v-if="select_component_obj">
          <div class="device-info__header--text">{{ select_component_obj.name }}</div>
          <div class="device-info__header--btn" v-if="select_component_obj.snow_id !== '1'" @click="updateLibraryVisible = true">
            <span class="iconfont el-icon-edit"></span>
            {{$t('messages.updataLibraryName')}}
          </div>
        </div>
        <div class="p-item" v-if="!selectDeviceDelete">
          <button @click="batchAddSymbolVisible = true" class="main-btn fill">
            <!-- <img class="btn-icon" src="../../assets/icons/personnel/导出.svg" /> -->
            <span class="btn-iconfont iconfont icon-Pell_tianjia"></span>
            <!-- 批量添加符号 -->
            {{$t('messages.batchAddSymbol')}}
          </button>
          <button @click="selectDeviceDelete = true" class="main-btn danger">
            <img class="btn-icon" src="../../assets/icons/personnel/删除.svg" />
            {{ $t('messages.batchDelete') }}
          </button>
          <input class="main-input" v-model.trim="searchSymbol" :placeholder="$t('messages.placeholder_1')" />
          <button class="main-btn fill" @click="searchSymbolClick">{{ $t('messages.search') }}</button>
        </div>
        <!-- <div class="p-item" v-if="selectDeviceExport">
          <button class="main-btn default" @click="toExportDevice">{{$t("messages.batchExportGDS")}}</button>
          <button class="main-btn default"
            @click="selectDeviceExport = false,resetSelection('personalSymbolTable')">{{$t("messages.cancel")}}</button>
        </div> -->
        <div class="p-item" v-if="selectDeviceDelete">
          <button class="main-btn default" @click="toDeleteSymbol">{{ $t('messages.delete') }}</button>
          <button class="main-btn default" @click=";(selectDeviceDelete = false), resetSelection('personalSymbolTable')">{{ $t('messages.cancel') }}</button>
        </div>
        <div class="p-table-device">
          <el-table v-loading="loading" ref="personalSymbolTable" :data="personalSymbolTableData" width="100%" height="100%" header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" v-if="selectDeviceExport || selectDeviceDelete" width="100" align="center"></el-table-column>
            <el-table-column :label="$t('messages.orderNumber')" width="100" type="index" align="center">
              <template #default="scope">
                <span>{{ (currentPage - 1) * pageSize + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.SymbolName')" prop="name" align="center"></el-table-column>
            <el-table-column :label="$t('messages.uploadStatus')" prop="is_public" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.is_public == 0">{{ $t('messages.notUpload') }}</div>
                <div v-else>{{ $t('messages.alreadyUpload') }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.uploadTime')" prop="public_time" align="center">
              <template slot-scope="scope">
                <div v-if="!scope.row.public_time">---</div>
                <div v-else>{{ scope.row.public_time }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('messages.operation')" align="center">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="upload" placement="right">
                  <img v-if="scope.row.is_public == 0" @click="toUploadSymbol(scope.row)" class="operateIcon" src="../../assets/icons/devices/上传到器件库.svg" />
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="update" placement="right">
                  <img v-if="scope.row.is_public == 1 && scope.row.is_update == 1" @click="toUploadSymbol(scope.row)" class="operateIcon" src="../../assets/icons/devices/更新到器件库.svg" />
                </el-tooltip>
                <img v-if="scope.row.is_public == 1 && scope.row.is_update == 0" class="operateIcon disabled" src="../../assets/icons/devices/更新到器件库2.svg" />
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('messages.copyTo')" :visible.sync="copyToTeamVisible" width="680px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 30px" class="selectbtype">
          <div class="item_title">{{ $t('messages.belong') }}：</div>
          <el-select class="selectClass" size="middle" style="width: 85%" v-model.trim="selectTeam" :placeholder="$t('messages.placeholder_0')">
            <el-option v-for="item in TeamOptions" :key="item.value" :label="item.label" :value="item.value">
              <div>
                <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px" src="../../assets/icons/personnel/团队名.svg" />
                {{ item.label }}
              </div>
            </el-option>
          </el-select>
          <div v-if="selectTeam">
            <img alt="" style="height: 20px; position: absolute; left: 95px; top: 5px" src="../../assets/icons/personnel/团队名.svg" />
          </div>
        </div>
        <div style="margin-top: 30px">
          <div class="item_title">{{ $t('messages.name') }}：</div>
          <div style="display: inline-block; width: 85%; vertical-align: top">
            <el-tooltip class="item" effect="dark" :content="
                selectTeamList
                  .map(item => {
                    return item.name
                  })
                  .join('，')
              " placement="bottom">
              <div class="item_class">
                {{
                  selectTeamList
                    .map(item => {
                      return item.name
                    })
                    .join('，')
                }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitCopy" :disabled="!selectTeam" :loading="copyLoading">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="copyToTeamVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.copyTo')" :visible.sync="copyToTeamProjectVisible" width="680px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 30px" class="selectbtype">
          <div class="item_title">{{ $t('messages.belong') }}：</div>
          <el-cascader class="selectManyClass must-left" size="middle" style="width: 82%" :options="TeamOptions" v-model="selectTeamProject">
            <template slot-scope="{ node, data }">
              <div @click="getTeamProjectData(node, data)">
                <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px" src="../../assets/icons/personnel/团队名.svg" />
                {{ data.label }}
              </div>
            </template>
          </el-cascader>
          <div v-if="selectTeamProject">
            <img alt="" style="height: 20px; position: absolute; left: 95px; top: 10px" src="../../assets/icons/personnel/团队名.svg" />
          </div>
        </div>
        <div style="margin-top: 30px">
          <div class="item_title">{{ $t('messages.name') }}：</div>
          <div style="display: inline-block; width: 85%; vertical-align: top">
            <el-tooltip class="item" effect="dark" :content="
                selectTeamList
                  .map(item => {
                    return item.name
                  })
                  .join('，')
              " placement="bottom">
              <div class="item_class">
                {{
                  selectTeamList
                    .map(item => {
                      return item.name
                    })
                    .join('，')
                }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitFileCopy" :disabled="!selectTeamProject" :loading="copyLoading">
          {{ $t('messages.sure') }}
        </el-button>
        <el-button type="cancel" @click="copyToTeamProjectVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.toShare')" :visible.sync="shareProjectCodeVisible" width="680px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="margin-top: 20px">
          <span>{{ $t('messages.shareUrl') }}：</span>
          <div class="urlInfo">{{ projectUrl }}</div>
          <div class="copyUrlInfo">
            <el-tooltip class="item" effect="dark" content="copy" placement="right">
              <i class="el-icon-document-copy" @click="shareProjectUrl"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem confirmFont">
          <div>{{ $t('messages.powerTips_11') }}</div>
        </div>
        <el-button type="sure" size="mini" @click="shareProjectCodeVisible = false">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="shareProjectCodeVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="deleteProjectVisible" width="680px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div class="confirmFont">
          <img alt="" class="btn-icon" src="../../assets/icons/personnel/警告.svg" />
          {{ $t('messages.powerTips_12') }}
        </div>
        <div style="margin-top: 30px">
          <div style="display: inline-block; margin-left: 20px; vertical-align: top">
            <el-tooltip class="item" effect="dark" :content="
                selectTeamList
                  .map(item => {
                    return item.name
                  })
                  .join('，')
              " placement="bottom">
              <div class="item_class">
                {{
                  selectTeamList
                    .map(item => {
                      return item.name
                    })
                    .join('，')
                }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="dismissDeleteChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDelete" :disabled="!dismissDeleteChecked" :loading="DeleteLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(deleteProjectVisible = false), (dismissDeleteChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="deleteFileVisible" width="680px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div class="confirmFont">
          <img alt="" class="btn-icon" src="../../assets/icons/personnel/警告.svg" />
          {{ $t('messages.powerTips_12') }}
        </div>
        <div style="margin-top: 30px">
          <div style="display: inline-block; margin-left: 20px; vertical-align: top">
            <el-tooltip class="item" effect="dark" :content="
                selectTeamList
                  .map(item => {
                    return item.name
                  })
                  .join('，')
              " placement="bottom">
              <div class="item_class">
                {{
                  selectTeamList
                    .map(item => {
                      return item.name
                    })
                    .join('，')
                }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="dismissDeleteChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDeleteFile" :disabled="!dismissDeleteChecked" :loading="DeleteLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(deleteFileVisible = false), (dismissDeleteChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="deleteCellVisible" width="680px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div class="confirmFont">
          <img alt="" class="btn-icon" src="../../assets/icons/personnel/警告.svg" />
          {{ $t('messages.powerTips_12') }}
        </div>
        <div style="margin-top: 30px">
          <div style="display: inline-block; margin-left: 20px; vertical-align: top">
            <el-tooltip class="item" effect="dark" :content="
                selectTeamList
                  .map(item => {
                    return item.name
                  })
                  .join('，')
              " placement="bottom">
              <div class="item_class">
                {{
                  selectTeamList
                    .map(item => {
                      return item.name
                    })
                    .join('，')
                }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="dismissDeleteChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDeleteCell" :disabled="!dismissDeleteChecked" :loading="DeleteLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(deleteCellVisible = false), (dismissDeleteChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.receiveShare')" :visible.sync="receiveShareVisible" width="680px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="margin-bottom: 20px">
          <el-input class="selectClass" v-model.trim="shareCode" size="middle" :placeholder="$t('messages.placeholder_1')"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" :loading="receiveShareLoading" @click="submitReceiveShare" :disabled="!shareCode.length > 0">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(receiveShareVisible = false), (shareCode = '')" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.receiveShare')" :visible.sync="receiveFileShareVisible" width="680px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="margin-bottom: 20px">
          <el-input class="selectClass" v-model.trim="shareCode" size="middle" :placeholder="$t('messages.placeholder_1')"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" :loading="receiveShareLoading" @click="submitReceiveFileShare" :disabled="!shareCode.length > 0">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(receiveFileShareVisible = false), (shareCode = '')" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="delDeviceVisible" width="575px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_17') }}？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="deleteDeviceChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDelDevice" :disabled="!deleteDeviceChecked" :loading="delDeviceLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(delDeviceVisible = false), (deleteDeviceChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="delSymbolVisible" width="575px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_25') }}？
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="deleteDeviceChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDelSymbol" :disabled="!deleteDeviceChecked" :loading="delSymbolLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(delSymbolVisible = false), (deleteDeviceChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.uploadTo')" :visible.sync="uploadDeviceVisible" width="575px" :show-close="false" :modal="false">
      <div class="dialog-body2">
        <el-form ref="deviceForm" :model="deviceForm">
          <el-form-item prop="name">
            <span slot="label">
              <span class="must">{{ $t('messages.name') }}：</span>
            </span>
            <el-input v-model.trim="deviceForm.name" size="middle" class="selectClass" style="width: 85%" clearable :placeholder="$t('messages.placeholder_1')" maxlength="16" @keyup.native="diykeyUp" disabled></el-input>
          </el-form-item>
          <el-form-item prop="label">
            <span slot="label">
              <span>&ensp;&thinsp;{{ $t('messages.Label') }}：</span>
            </span>
            <div class="form-label">
              <div class="form-labrl__item" v-for="(v, k) in deviceForm.label" :key="k">
                <el-input size="middle" @blur="labelkeyUp(k)" @keyup.native="diykeyUp" v-model="deviceForm.label[k]" maxlength="10" show-word-limit class="selectClass" style="width: 90%"></el-input>
                <div v-if="k == 0" @click="addDeviceLabel" class="addLabel"><span class="el-icon-circle-plus"></span>
                </div>
                <div v-else @click="delDeviceLabel(k)" class="delLabel"><span class="el-icon-remove-outline"></span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="label">
            <span slot="label">
              <span>&ensp;&thinsp;{{ $t('messages.note') }}：</span>
            </span>
            <div style="margin-top: 10px">
              <el-input type="textarea" placeholder="（选填）" :rows="6" style="width: 85%" maxlength="128" show-word-limit v-model="deviceForm.note"></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitUploadDevice">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(uploadDeviceVisible = false), resetForm('deviceForm')" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.uploadTo')" :visible.sync="uploadSymbolVisible" width="575px" :show-close="false" :modal="false">
      <div class="dialog-body2">
        <el-form ref="deviceForm" :model="deviceForm">
          <el-form-item prop="name">
            <span slot="label">
              <span class="must">{{ $t('messages.name') }}：</span>
            </span>
            <el-input v-model.trim="deviceForm.name" size="middle" class="selectClass" style="width: 85%" clearable :placeholder="$t('messages.placeholder_1')" maxlength="16" @keyup.native="diykeyUp" disabled></el-input>
          </el-form-item>
          <el-form-item prop="label">
            <span slot="label">
              <span>&ensp;&thinsp;{{ $t('messages.Label') }}：</span>
            </span>
            <div class="form-label">
              <div class="form-labrl__item" v-for="(v, k) in deviceForm.label" :key="k">
                <el-input size="middle" @blur="labelkeyUp(k)" @keyup.native="diykeyUp" v-model="deviceForm.label[k]" maxlength="10" show-word-limit class="selectClass" style="width: 90%"></el-input>
                <div v-if="k == 0" @click="addDeviceLabel" class="addLabel"><span class="el-icon-circle-plus"></span>
                </div>
                <div v-else @click="delDeviceLabel(k)" class="delLabel"><span class="el-icon-remove-outline"></span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="label">
            <span slot="label">
              <span>&ensp;&thinsp;{{ $t('messages.note') }}：</span>
            </span>
            <div style="margin-top: 10px">
              <el-input type="textarea" placeholder="（选填）" :rows="6" style="width: 85%" maxlength="128" show-word-limit v-model="deviceForm.note"></el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitUploadSymbol">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(uploadSymbolVisible = false), resetForm('deviceForm')" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.createNewLibrary')" :visible.sync="createNewLibraryVisible" width="680px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <span>{{ $t('messages.libraryName') }}：</span>
        <el-input size="middle" v-model.trim="libraryName" style="width: 88%" maxlength="10" show-word-limit></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitCreateLibrary" :disabled="!libraryName.length > 0">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(createNewLibraryVisible = false), (libraryName = '')" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.updataLibraryName')" :visible.sync="updateLibraryVisible" width="680px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <span>{{ $t('messages.libraryName') }}：</span>
        <el-input size="middle" v-model.trim="libraryName" style="width: 88%" maxlength="10" show-word-limit></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitUpdateLibrary" :disabled="!libraryName.length > 0">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(updateLibraryVisible = false), (libraryName = '')" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.batchAddComponent')" :visible.sync="batchAddComponentVisible" width="60%" :show-close="false" :modal="false" @open="searchStart">
      <div class="dialog-body-search">
        <div class="search-header">
          <el-select style="width: 200px" class="componentSelect" size="mini" @change="toSearchComponent" v-model="search_library_type">
            <el-option v-for="item in $t('messages.batchAddComponentTypeOptions')" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select style="width: 200px" v-if="search_library_type == 0" class="componentSelect" size="mini" v-model="search_library_id" @change="toSearchComponent">
            <el-option v-for="item in libraryOptions" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <!-- <el-select style="width: 200px;" v-if="search_library_type==4" class="componentSelect" size="mini" v-model="search_project_id">
            <el-option v-for="item in projectOptionList" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select> -->
          <el-cascader style="width: 200px" v-if="search_library_type == 4" class="componentSelect" size="mini" v-model="search_project_id" :options="projectOptionList">
            <template slot-scope="{ node, data }">
              <div @click="getTeamProjectData(node, data)">
                <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px" src="../../assets/icons/personnel/团队名.svg" />
                {{ data.label }}
              </div>
            </template>
          </el-cascader>
          <el-select style="width: 200px" v-if="search_library_type == 1" class="componentSelect" size="mini" v-model="search_team_id" @change="toSearchComponent">
            <el-option v-for="item in teamOptionList" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select style="width: 200px" class="componentSelect" size="mini" v-model="search_component_type" v-show="false">
            <el-option v-for="item in $t('messages.componentOptions')" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-input style="width: 200px" size="mini" v-model="searchComponentName" :placeholder="$t('messages.placeholder_seach_component')"></el-input>
          <el-button size="mini" type="primary" @click="toSearchComponent">{{ $t('messages.search') }}</el-button>
        </div>
        <div class="search-body">
          <el-table ref="searchComponentTable" v-loading="searchComponentLoading" :data="searchComponentTableData" height="60vh" header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" width="50" align="center"></el-table-column>
            <el-table-column type="index" width="100" align="center" label="序号"></el-table-column>
            <el-table-column label="器件名称" align="left" prop="name"></el-table-column>
            <el-table-column label="器件类型" width="200" align="left" prop="cell_component_type">
              <template slot-scope="scope">
                <div v-if="scope.row.cell_component_type == 1">{{ $t('messages.fixedDevices') }}</div>
                <div v-else>{{ $t('messages.parameterizedDevice') }}</div>
              </template>
            </el-table-column>
            <el-table-column v-if="search_library_type == 1 || search_library_type == 2" label="创建者" width="200" align="left" prop="display_name"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" :loading="replaceLoading" @click="submitBatchAddComponent(false, 0)" size="mini">{{ $t('messages.apply') }}</el-button>
        <el-button type="sure" :loading="replaceLoading" size="mini" @click="submitBatchAddComponent(true, 0)">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="cancelSubmitAdd" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.batchAddSymbol')" :visible.sync="batchAddSymbolVisible" width="60%" :show-close="false" :modal="false" @open="searchStart">
      <div class="dialog-body-search">
        <div class="search-header">
          <el-select style="width: 200px" class="componentSelect" size="mini" @change="toSearchSymbol" v-model="search_library_type">
            <el-option v-for="item in $t('messages.batchAddSymbolTypeOptions')" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-select style="width: 200px" v-if="search_library_type == 0" class="componentSelect" size="mini" v-model="search_library_id" @change="toSearchSymbol">
            <el-option v-for="item in libraryOptions" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <!-- <el-select style="width: 200px;" v-if="search_library_type==4" class="componentSelect" size="mini" v-model="search_project_id">
            <el-option v-for="item in projectOptionList" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select> -->
          <el-cascader style="width: 200px" v-if="search_library_type == 4" class="componentSelect" size="mini" v-model="search_project_id" :options="projectOptionList">
            <template slot-scope="{ node, data }">
              <div @click="getTeamProjectData(node, data)">
                <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px" src="../../assets/icons/personnel/团队名.svg" />
                {{ data.label }}
              </div>
            </template>
          </el-cascader>
          <el-select style="width: 200px" v-if="search_library_type == 1" class="componentSelect" size="mini" v-model="search_team_id" @change="toSearchSymbol">
            <el-option v-for="item in teamOptionList" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-input style="width: 200px" size="mini" v-model="searchComponentName" :placeholder="$t('messages.placeholder_seach_symbol')"></el-input>
          <el-button size="mini" type="primary" @click="toSearchSymbol">{{ $t('messages.search') }}</el-button>
        </div>
        <div class="search-body">
          <el-table ref="searchSymbolTable" v-loading="searchSymbolLoading" :data="searchSymbolTableData" height="60vh" header-cell-class-name="table_header">
            <el-table-column type="selection" key="checkbox" width="50" align="center"></el-table-column>
            <el-table-column type="index" width="200" align="left" label="序号"></el-table-column>
            <el-table-column label="符号名称" align="left" prop="name"></el-table-column>
            <el-table-column v-if="search_library_type == 1 || search_library_type == 2" label="创建者" width="200" align="left" prop="display_name"></el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" :loading="replaceLoading" @click="submitBatchAddSymbol(false, 0)" size="mini">{{ $t('messages.apply') }}</el-button>
        <el-button type="sure" :loading="replaceLoading" size="mini" @click="submitBatchAddSymbol(true, 0)">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="cancelSubmitAdd" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.exportLibraryFile')" :visible.sync="batchExportLibraryVisible" width="500px" :show-close="false" :modal="false" @open="exportLibraryStart">
      <div class="dialog-body">
        <div class="export-group">
          <div class="group-txt">{{ $t('messages.Group') }}：</div>
          <div class="group-select">
            <el-select style="width: 300px" class="componentSelect" size="mini" v-model="export_library_type">
              <el-option v-for="item in $t('messages.exportLibraryTypeOptions')" :key="item.label" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
        <div class="export-group" v-if="export_library_type == 2">
          <div class="group-txt">{{ $t('messages.select') }}：</div>
          <div class="group-select">
            <el-select style="width: 300px" class="componentSelect" size="mini" v-model="export_team_id">
              <el-option v-for="item in teamOptionList" :key="item.label" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
        <div class="export-group" v-if="export_library_type == 5">
          <div class="group-txt">{{ $t('messages.select') }}：</div>
          <div class="group-select">
            <el-cascader style="width: 300px" class="componentSelect" size="mini" v-model="export_project_id" :options="projectOptionList">
              <template slot-scope="{ node, data }">
                <div @click="getTeamProjectData(node, data)">
                  <img alt="" style="height: 20px; position: relative; top: 5px; margin-right: 5px" src="../../assets/icons/personnel/团队名.svg" />
                  {{ data.label }}
                </div>
              </template>
            </el-cascader>
          </div>
        </div>
        <div class="export-group" v-if="export_library_type == 1">
          <div class="group-txt">{{ $t('messages.ComponentLibrary') }}：</div>
          <div class="group-select">
            <el-select style="width: 300px" class="componentSelect" size="mini" v-model="export_library_id">
              <el-option v-for="item in libraryOptions" :key="item.label" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
        <div class="export-group" v-if="export_library_type == 1">
          <div class="group-txt">{{ $t('messages.symbolLibrary') }}：</div>
          <div class="group-select">
            <el-select style="width: 300px" class="componentSelect" size="mini" v-model="export_symbol_id">
              <el-option v-for="item in symbolOptions" :key="item.label" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="sure" size="mini" @click="submitExportLibrary">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click="batchExportLibraryVisible = false" size="mini">{{ $t('messages.cancel') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.Warning')" :visible.sync="deleteLibraryVisible" width="575px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.powerTips_29') }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="confirmItem">
          <el-checkbox v-model.trim="deleteDeviceChecked">{{ $t('messages.powerTips_13') }}</el-checkbox>
        </div>
        <el-button type="sure" size="mini" @click="submitDeleteLibrary" :disabled="!deleteDeviceChecked" :loading="delSymbolLoading">{{ $t('messages.sure') }}</el-button>
        <el-button type="cancel" @click=";(deleteLibraryVisible = false), (deleteDeviceChecked = false)" size="mini">
          {{ $t('messages.cancel') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.tips')" :visible.sync="existsCellNameVisible" width="575px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.pcellExistsNameTips') }}
        </div>
      </div>
      <div style="display: inline-block; margin-left: 30px; margin-top:30px; vertical-align: top">
        <div class="item_class">
          {{existsCellList.join('，')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="replaceLoading" type="sure" size="mini" @click="submitBatchAddComponent(false, 1)">
          {{ $t('messages.yes') }}
        </el-button>
        <el-button :loading="replaceLoading" type="cancel" @click="cancelSubmitReplaceCellName" size="mini">{{ $t('messages.no') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('messages.tips')" :visible.sync="existsSymbolNameVisible" width="575px" :show-close="false" :modal="false">
      <div class="dialog-body">
        <div style="display: flex">
          <i class="el-icon-warning-outline" style="color: #e15423; font-size: 20px; margin-right: 10px"></i>
          {{ $t('messages.pcellExistsNameTips') }}
        </div>
      </div>
      <div style="display: inline-block; margin-left: 30px; margin-top:30px; vertical-align: top">
        <div class="item_class">
          {{existsCellList.join('，')}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="replaceLoading" type="sure" size="mini" @click="submitBatchAddSymbol(false, 1)">
          {{ $t('messages.yes') }}
        </el-button>
        <el-button :loading="replaceLoading" type="cancel" @click="cancelSubmitReplaceCellName" size="mini">{{ $t('messages.no') }}</el-button>
      </span>
    </el-dialog>
    <div class="errWindow">
      <el-dialog :modal="false" :title="errTitle" :visible.sync="errDialogVisible" :close-on-click-modal="false">
        <div class="info">
          <i style="width: 22px; height: 22px; padding-right: 10px; color: #ec3838; font-size: 22px" :class="isError ? 'iconfont icon-sousuoshanchu' : 'iconfont icon-jinggao'"></i>
          <span>{{ errInfo }}</span>
          <br />
        </div>
        <span slot="footer" class="dialog-footer">
          <div>
            <el-button type="primary" @click="errDialogVisible = false" size="mini">{{ $t('messages.sure') }}</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Md5 from "js-md5";
import {
  getUserAllproject,
  batchCopyProject,
  getTeamList,
  getShareTeamProjectCode,
  receiveShareCode,
  deleteProject,
  getUserDeviceList,
  deleteDevice,
  deleteSymbol,
  uploadDevice,
  uploadSymbol,
  getUserSymbol,
  copyFiles,
  deleteFiles,
  createLibrary,
  createSymbol,
  getLibraryList,
  getSymbolList,
  updateLibraryName,
  updateSymbolName,
  getPersonalLibraryComponent,
  getTeamLibraryComponent,
  getPublicLibraryComponent,
  getOfficialLibraryComponent,
  getPDKLibraryComponent,
  batchAddComponent,
  batchExportLibrary,
  batchExportToZip,
  getPersonalLibrarySymbol,
  getTeamLibrarySymbol,
  getPublicLibrarySymbol,
  getOfficialLibrarySymbol,
  getPDKLibrarySymbol,
  batchAddSymbol,
  deleteComponentLibrary,
  deleteSymbolLibrary,
} from "@/api/api";
import {
  getBlobFile,
  searchFile_api,
  getTeamProjectList_api,
  getFileinfo_api,
  getUserProjectList_api,
  getTeamList_api,
} from "@/api/file/file.js";
import {
  batchProjectCopyTo,
  batchFileCopyTo,
  projectShareTo,
  fileShareTo,
} from "./filePublicFn";
import {
  getObjectDataFn,
  downloadFileData,
} from "@/components/homes/fileList/function/objectStorageFn";
import { deepCopy, downloadZip } from "../../utils/utils";
import bus from "../common/bus";
const apiKey = localStorage.getItem("apiKey");
const minio_name = localStorage.getItem("minio_name");
export default {
  data() {
    return {
      loading: false,
      projectFocus: true,
      clFocus: false,
      selectShare: false,
      selectCopy: false,
      selectDelete: false,
      selectTeam: "",
      selectTeamProject: "",
      copyToTeamVisible: false,
      copyToTeamProjectVisible: false,
      shareProjectCodeVisible: false,
      receiveShareVisible: false,
      receiveFileShareVisible: false,
      deleteProjectVisible: false,
      deleteFileVisible: false,
      deleteCellVisible: false,
      delDeviceVisible: false,
      delSymbolVisible: false,
      uploadDeviceVisible: false,
      uploadSymbolVisible: false,
      dismissDeleteChecked: false,
      deleteDeviceChecked: false,
      selectDeviceExport: false,
      selectDeviceDelete: false,
      TeamOptions: [],
      selectTeamList: [],
      selectDeviceList: [],
      personalTableData: [],
      personalDeviceTableData: [],
      currentPage: 1,
      total: 0,
      pageSize: 20,
      itemName: "",
      projectUrl: "",
      shareCode: "",
      searchDevice: "",
      deviceForm: {
        name: "",
        label: [],
        mote: "",
        snow_id: "",
      },
      copyLoading: false,
      DeleteLoading: false,
      delDeviceLoading: false,
      delSymbolLoading: false,
      receiveShareLoading: false,
      symbolFocus: false,
      checkProject: false,
      checkFile: false,
      checkCell: false,
      currentProject: null,
      currentFile: null,
      currentCell: null,
      personalFileTableData: [],
      personalCellTableData: [],
      filePageSize: 20,
      cellPageSize: 20,
      fileTotal: 0,
      cellTotal: 0,
      fileSearchName: "",
      cellSearchName: "",
      searchSymbol: "",
      personalSymbolTableData: [],
      cellCurrentPage: 1,
      fileCurrentPage: 1,
      selectTypes: 1,
      libraryList: [],
      select_component_obj: {},
      select_component_index: 0,
      createNewLibraryVisible: false,
      libraryName: "",
      select_component_type: 0,
      checkLibraryDelete: false,
      updateLibraryVisible: false,
      batchAddComponentVisible: false,
      batchAddSymbolVisible: false,
      searchComponentName: "",
      search_component_type: 1,
      search_project_id: "",
      search_library_type: 0,
      search_library_id: "",
      search_team_id: "",
      projectOptionList: [],
      teamOptionList: [],
      searchComponentLoading: false,
      searchSymbolLoading: false,
      searchComponentTableData: [],
      searchSymbolTableData: [],
      libraryOptions: [],
      all_libraryOptions: [],
      search_start: 0,
      search_rows: 200,
      batchExportLibraryVisible: false,
      symbolOptions: [],
      export_project_id: "",
      export_library_type: 1,
      export_library_id: "",
      export_team_id: "",
      export_symbol_id: "",
      search_symbol_type: 0,
      deleteLibraryVisible: false,
      existsCellNameVisible: false,
      existsSymbolNameVisible: false,
      existsCellList: [],
      replaceLoading: false,
      //错误弹窗
      errDialogVisible: false,
      errTitle: "",
      errInfo: "",
      isError: true,
    };
  },
  mounted() {
    bus.$on("errDialog", (data) => {
      this.errDialog(this.$t("messages.wrong"), data, true);
    });
  },
  methods: {
    handleClose(done) {
      done();
    },
    errDialog(title, info, err) {
      this.errTitle = title;
      this.errInfo = info;
      this.isError = err;
      this.errDialogVisible = true;
    },
    resetSelection(tableName) {
      try {
        this.$refs[tableName].clearSelection();
      } catch {
        return;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    cancelCheckLibraryDelete() {
      this.checkLibraryDelete = false;
      for (let i = 0; i < this.libraryList.length; i++) {
        this.libraryList[i].checked = false;
      }
    },
    //限制输入特殊字符
    diykeyUp(e) {
      e = e.replace(
        /[`!@#$%^*\=<>?:"{}|,\/;'\\\·！@#￥%……*（）——\={}|《》？：“”【】、；‘’，。、]/g, //NOSONAR
        ""
      );
      // e.target.value = e.target.value.replace(
      //   /[`!@#$%^*\=<>?:"{}|,\/;'\\\·！@#￥%……*（）——\={}|《》？：“”【】、；‘’，。、]/g, //NOSONAR
      //   ''
      // )
      // e.target.value = e.target.value.replace(/\ +/g, '')
      // e.target.value = e.target.value.replace(/[\r\n]/g, '')
    },
    labelkeyUp(index) {
      let str = deepCopy(this.deviceForm.label[index]);
      str = str.replace(
        /[`!@#$%^*\=<>?:"{}|,\/;'\\\·！@#￥%……*（）——\={}|《》？：“”【】、；‘’，。、]/g, //NOSONAR
        ""
      );
      str = str.replace(/\ +/g, "");
      str = str.replace(/[\r\n]/g, "");
      this.deviceForm.label[index] = str;
    },
    tableIndex(index) {
      return (this.currentPage - 1) * this.pageSize + index + 1;
    },
    initSelectStatus() {
      this.selectShare = false;
      this.selectCopy = false;
      this.selectDelete = false;
      this.selectDeviceExport = false;
      this.selectDeviceDelete = false;
      this.currentPage = 1;
    },
    vChange(index) {
      this.initSelectStatus();
      if (index === 0) {
        this.projectFocus = true;
        this.clFocus = false;
        this.symbolFocus = false;
        this.getData(0, this.pageSize, this.itemName);
      } else if (index === 1) {
        this.projectFocus = false;
        this.clFocus = true;
        this.symbolFocus = false;
        this.getLibraryInfo();
        this.getDeviceListData();
      } else {
        this.symbolFocus = true;
        this.projectFocus = false;
        this.clFocus = false;
        this.getLibraryInfo();
        this.getSymbolListData();
      }
    },
    selectDeviceChange(row, index) {
      if (this.checkLibraryDelete) return;
      this.select_component_index = index;
      this.select_component_obj = row;
      this.select_component_type = 0;
      if (this.clFocus) {
        this.getDeviceListData();
      } else {
        this.getSymbolListData();
      }
    },
    getData(start, end, keyword) {
      let params = {
        start: start,
        end: end,
        keyword: keyword,
      };
      this.loading = true;
      if (this.projectFocus) {
        this.personalTableData = [];
        getUserAllproject(params).then((res) => {
          if (res.code / 1000 === 200) {
            this.personalTableData = res.data.data;
            this.total = res.data.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading = false;
        });
      }
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      let start = (this.currentPage - 1) * pageSize;
      let end = this.currentPage * pageSize;
      if (this.projectFocus) {
        this.getData(start, end, this.itemName);
      } else if (this.clFocus) {
        this.getDeviceListData();
      } else {
        this.getDeviceListData();
      }
    },
    fileSizeChange(pageSize) {
      this.filePageSize = pageSize;
      let start = (this.fileCurrentPage - 1) * pageSize;
      this.getFilesData(start, pageSize, this.checkProjectGNS);
    },
    cellSizeChange(pageSize) {
      this.cellPageSize = pageSize;
      let start = (this.cellCurrentPage - 1) * pageSize;
      this.getCellsData(start, pageSize, this.checkProjectGNS);
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      let start = (currentPage - 1) * this.pageSize;
      let end = currentPage * this.pageSize;
      if (this.projectFocus) {
        this.getData(start, end, this.itemName);
      } else if (this.clFocus) {
        this.getDeviceListData();
      }
    },
    fileCurrentChange(currentPage) {
      this.fileCurrentPage = currentPage;
      let start = (currentPage - 1) * this.filePageSize;
      this.getFilesData(start, this.filePageSize, this.checkProjectGNS);
    },
    cellCurrentChange(currentPage) {
      this.cellCurrentPage = currentPage;
      let start = (currentPage - 1) * this.cellPageSize;
      this.getCellsData(start, this.cellPageSize, this.checkProjectGNS);
    },
    searchItemName() {
      this.currentPage = 1;
      this.pageSize = 20;
      let start = 0;
      let end = this.currentPage * this.pageSize;
      this.getData(start, end, this.itemName);
    },
    batchCopyProjectApi(project_list, snow_team_id) {
      const params = {
        project_gns_list: project_list,
        snow_team_id: snow_team_id,
        type: "user",
      };
      this.copyLoading = true;
      batchCopyProject(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.copySuccess"));
          this.copyToTeamVisible = false;
          this.selectCopy = false;
        } else {
          this.$message.error(res.message);
        }
        this.copyLoading = false;
      });
      this.resetSelection("personalTable");
    },
    batchCopyFileApi(project_list, snow_team_id) {
      const params = {
        file_snow_ids: project_list,
        target_project_gns: snow_team_id,
        copy_type: "team",
      };
      this.copyLoading = true;
      copyFiles(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.copySuccess"));
          this.copyToTeamProjectVisible = false;
          this.selectCopy = false;
        } else {
          this.$message.error(res.message);
        }
        this.copyLoading = false;
      });
      this.copyLoading = false;
    },
    getTeamListData(start, rows, keyword = "") {
      let params = {
        start: start,
        rows: rows,
        key_word: keyword,
      };
      getTeamList(params).then((res) => {
        if (res.code / 1000 === 200) {
          let team_list = [];
          if (res.data.data.length > 0) {
            team_list = res.data.data.map((item) => {
              const dicts = {
                label: item.team_name,
                value: item.snow_team_id,
                gns: item.gns,
                snow_id: item.snow_team_id,
                children: [],
              };
              return dicts;
            });
            this.TeamOptions = team_list;
          }
        }
      });
    },
    toCopy() {
      this.selectTeamList = this.$refs.personalTable.selection;
      if (this.selectTeamList.length > 0) {
        this.copyToTeamVisible = true;
        this.getTeamListData(0, 100);
      } else {
        this.$message.error(this.$t("messages.error_4"));
      }
    },
    toFileCopy() {
      this.selectTeamList = this.$refs.personalFileTable.selection;
      if (this.selectTeamList.length > 0) {
        this.copyToTeamProjectVisible = true;
        this.getTeamListData(0, 100);
      } else {
        this.$message.error(this.$t("messages.error_4"));
      }
    },
    toShare() {
      this.selectTeamList = this.$refs.personalTable.selection;
      if (this.selectTeamList.length > 0) {
        this.getProjectShareCode();
      } else {
        this.$message.error(this.$t("messages.error_4"));
      }
    },
    toFileShare() {
      this.selectTeamList = this.$refs.personalFileTable.selection;
      if (this.selectTeamList.length > 0) {
        this.getFileShareCode();
      } else {
        this.$message.error(this.$t("messages.error_4"));
      }
    },
    toDelete() {
      this.selectTeamList = this.$refs.personalTable.selection;
      if (this.selectTeamList.length > 0) {
        this.deleteProjectVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_4"));
      }
    },
    toFileDelete() {
      this.selectTeamList = this.$refs.personalFileTable.selection;
      if (this.selectTeamList.length > 0) {
        this.deleteFileVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_4"));
      }
    },
    toCellDelete() {
      this.selectTeamList = this.$refs.personalCellTable.selection;
      if (this.selectTeamList.length > 0) {
        this.deleteCellVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_4"));
      }
    },
    async submitCopy() {
      const snow_team_id = this.selectTeam;
      // const select_project_gns = this.selectTeamList.map((item) => {
      //   return item.gns;
      // });
      this.copyLoading = true;
      let success = await batchProjectCopyTo(
        this.selectTeamList,
        snow_team_id,
        2,
        1
      );

      if (success) {
        this.$message.success(this.$t("messages.copySuccess"));
        this.copyToTeamVisible = false;
        this.selectCopy = false;
      } else {
        this.$message.error(this.$t("messages.operationFailed"));
      }
      this.copyLoading = false;
      this.resetSelection("personalTable");
    },
    async submitFileCopy() {
      this.copyLoading = true;
      const snow_team_gns = this.selectTeamProject[1];
      const team_snow_id = this.selectTeamProject[0];
      let success = await batchFileCopyTo(
        this.selectTeamList,
        snow_team_gns,
        team_snow_id,
        2,
        1
      );
      if (success) {
        this.$message.success(this.$t("messages.copySuccess"));
        this.copyToTeamProjectVisible = false;
        this.selectCopy = false;
      } else {
        this.$message.error(this.$t("messages.operationFailed"));
      }
      this.copyLoading = false;
      this.resetSelection("personalFileTable");
    },
    shareProjectUrl() {
      let input = document.createElement("input");
      input.value = this.projectUrl;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy"); //NOSONAR
      document.body.removeChild(input);
      this.$message.success(this.$t("messages.shareCodeCopySuccess"));
    },
    getProjectShareCode() {
      const select_project_gns = this.selectTeamList.map((item) => {
        return item.gns;
      });
      const params = {
        snow_project_gns_list: select_project_gns,
        snow_team_id: "",
        share_type: "user",
      };
      getShareTeamProjectCode(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.projectUrl = res.data.code;
          this.shareProjectCodeVisible = true;
          this.selectShare = false;
        } else {
          this.$message.error(res.message);
        }
      });
      this.resetSelection("personalTable");
    },
    getFileShareCode() {
      const select_project_gns = this.selectTeamList.map((item) => {
        return item.gns;
      });
      const params = {
        snow_project_gns_list: select_project_gns,
        snow_team_id: "",
        share_type: "user",
      };
      getShareTeamProjectCode(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.projectUrl = res.data.code;
          this.shareProjectCodeVisible = true;
          this.selectShare = false;
        } else {
          this.$message.error(res.message);
        }
      });
      this.resetSelection("personalFileTable");
    },
    async submitReceiveShare() {
      if (this.shareCode.length > 0) {
        this.receiveShareLoading = true;
        try {
          let all_share_list = await receiveShareCode(
            this.shareCode,
            "",
            "project"
          );
          if (all_share_list.data.length == 0) {
            this.$message.error(this.$t("messages.shareNoFile"));
            this.receiveShareLoading = false;
            return;
          }
          let success = await projectShareTo(all_share_list.data, "", 1, 1);
          if (success) {
            this.$message.success(this.$t("messages.operationSuccess"));
            this.receiveShareVisible = false;
            this.start = 0;
            this.currentPage = 1;
            let end = this.currentPage * this.pageSize;
            setTimeout(() => {
              this.getData(0, end, this.itemName);
            }, 1000);
          } else {
            this.$message.error(this.$t("messages.operationFailed"));
          }
        } catch {
          this.shareCode = "";
          this.receiveShareLoading = false;
        }
        this.shareCode = "";
        this.receiveShareLoading = false;
      }
    },
    async submitReceiveFileShare() {
      if (this.shareCode.length > 0) {
        this.receiveShareLoading = true;
        let all_share_list = await receiveShareCode(
          this.shareCode,
          this.checkProjectGNS,
          "file"
        );
        if (all_share_list.data.length == 0) {
          this.$message.error(this.$t("messages.shareNoFile"));
          this.receiveShareLoading = false;
          return;
        }
        let file_info_list = await getFileinfo_api({
          start: 0,
          rows: 100,
          gns_ids: all_share_list.data.map((item) => {
            return item.gns;
          }),
        });
        let share_file_info = file_info_list.data.data.map((item) => {
          return item._source;
        });

        let success = await fileShareTo(
          share_file_info,
          this.checkProjectGNS,
          "",
          1,
          1
        );
        if (success) {
          this.$message.success(this.$t("messages.operationSuccess"));
          this.receiveFileShareVisible = false;
          this.start = 0;
          this.currentPage = 1;
          let end = this.currentPage * this.pageSize;
          setTimeout(() => {
            this.getData(0, end, this.itemName);
          }, 1000);
        } else {
          this.$message.error(this.$t("messages.operationFailed"));
        }
        this.shareCode = "";
        this.receiveShareLoading = false;
        // receiveShareCode(this.shareCode, this.checkProjectGNS, "file").then((res) => {
        //   if (res.code / 1000 === 200) {
        //     this.$message.success(this.$t('messages.operationSuccess'));
        //     this.receiveFileShareVisible = false;
        //     this.start = 0;
        //     this.currentPage = 1;
        //     let end = this.currentPage * this.pageSize;
        //     this.getData(0, end, this.itemName);
        //   } else {
        //     this.$message.error(res.message);
        //   }
        //   this.shareCode = "";
        //   this.receiveShareLoading = false;
        // }).catch(() => {
        //   this.shareCode = "";
        //   this.receiveShareLoading = false;
        // });
      }
    },
    deleteProjectApi() {
      const select_project_gns = this.selectTeamList.map((item) => {
        return item.gns;
      });
      const params = {
        project_gns_list: select_project_gns,
        snow_team_id: "",
        delete_type: "user",
      };
      this.DeleteLoading = true;
      deleteProject(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.selectDelete = false;
          let deleteIndex = [];
          for (let i in this.personalTableData) {
            for (let j in select_project_gns) {
              if (select_project_gns[j] === this.personalTableData[i].gns) {
                deleteIndex.push(i);
              }
            }
          }
          for (let i = deleteIndex.length - 1; i >= 0; i--) {
            this.personalTableData.splice(deleteIndex[i], 1);
          }
          this.total -= deleteIndex.length;
          this.deleteProjectVisible = false;
        } else {
          this.$message.error(res.message);
        }
        this.DeleteLoading = false;
      });
      this.resetSelection("personalFileTable");
    },
    deleteFileApi() {
      const select_file_gns = this.selectTeamList.map((item) => {
        return item.gns;
      });
      const params = {
        gns: select_file_gns,
        category: "文件",
      };
      this.DeleteLoading = true;
      deleteFiles(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.selectDelete = false;
          let deleteIndex = [];
          for (let i in this.personalFileTableData) {
            for (let j in select_file_gns) {
              if (select_file_gns[j] === this.personalFileTableData[i].gns) {
                deleteIndex.push(i);
              }
            }
          }
          for (let i = deleteIndex.length - 1; i >= 0; i--) {
            this.personalFileTableData.splice(deleteIndex[i], 1);
          }
          this.total -= deleteIndex.length;
          this.deleteFileVisible = false;
        } else {
          this.$message.error(res.message);
        }
        this.DeleteLoading = false;
      });
      this.resetSelection("personalCellTable");
    },
    deleteCellApi() {
      const select_cell_gns = this.selectTeamList.map((item) => {
        return item.gns;
      });
      const params = {
        gns: select_cell_gns,
        category: "CELL",
      };
      this.DeleteLoading = true;
      deleteFiles(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.selectDelete = false;
          let deleteIndex = [];
          for (let i in this.personalCellTableData) {
            for (let j in select_cell_gns) {
              if (select_cell_gns[j] === this.personalCellTableData[i].gns) {
                deleteIndex.push(i);
              }
            }
          }
          for (let i = deleteIndex.length - 1; i >= 0; i--) {
            this.personalCellTableData.splice(deleteIndex[i], 1);
          }
          this.total -= deleteIndex.length;
          this.deleteCellVisible = false;
        } else {
          this.$message.error(res.message);
        }
        this.DeleteLoading = false;
      });
      this.resetSelection("personalTable");
    },
    submitDelete() {
      this.deleteProjectApi();
    },
    submitDeleteFile() {
      this.deleteFileApi();
    },
    submitDeleteCell() {
      this.deleteCellApi();
    },
    getDeviceListData() {
      const params = {
        start: (this.currentPage - 1) * this.pageSize,
        rows: this.pageSize,
        key_words: this.searchDevice,
        library_snow_id: this.select_component_obj.snow_id,
        cell_component_type:
          this.select_component_type == 0
            ? [1, 2]
            : [this.select_component_type],
      };
      this.loading = true;
      getUserDeviceList(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.total = res.data.total;
          this.personalDeviceTableData = res.data.data.map((item) => {
            return item._source;
          });
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    getSymbolListData() {
      const params = {
        start: (this.currentPage - 1) * this.pageSize,
        rows: this.pageSize,
        key_words: this.searchSymbol,
        library_snow_id: this.select_component_obj.snow_id,
        cell_component_type:
          this.select_component_type == 0
            ? [1, 2]
            : [this.select_component_type],
      };
      this.loading = true;
      getUserSymbol(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.total = res.data.total;
            this.personalSymbolTableData = res.data.data;
          } else {
            this.$message.error(res.message);
          }
          this.loading = false;
        })
        .catch(() => {
          this.personalSymbolTable = [];
          this.loading = false;
        });
    },
    searchDeviceClick() {
      this.currentPage = 1;
      this.getDeviceListData();
    },
    searchSymbolClick() {
      this.currentPage = 1;
      this.getSymbolListData();
    },
    submitUploadDevice() {
      const params = {
        component_type: 1,
        snow_id: this.deviceForm.snow_id,
        name: this.deviceForm.name,
        notes: this.deviceForm.note,
        label_list: this.deviceForm.label,
      };
      uploadDevice(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.uploadSuccess"));
          this.currentPage = 1;
          this.getDeviceListData();
          this.uploadDeviceVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    submitUploadSymbol() {
      const params = {
        symbol_group: 1,
        snow_id: this.deviceForm.snow_id,
        name: this.deviceForm.name,
        notes: this.deviceForm.note,
        label_list: this.deviceForm.label,
      };
      uploadSymbol(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.uploadSuccess"));
          this.currentPage = 1;
          this.getSymbolListData();
          this.uploadSymbolVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    submitUpdateDevice(row) {
      this.deviceForm = {
        name: row.name,
        label: row.label,
        note: row.note,
        snow_id: row.snow_id,
      };
      this.uploadDeviceVisible = true;
    },
    toDeleteDevice() {
      this.selectDeviceList = this.$refs.personalDeviceTable.selection;
      if (this.selectDeviceList.length > 0) {
        this.delDeviceVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_1"));
      }
    },
    toDeleteSymbol() {
      this.selectDeviceList = this.$refs.personalSymbolTable.selection;
      if (this.selectDeviceList.length > 0) {
        this.delSymbolVisible = true;
      } else {
        this.$message.error(this.$t("messages.error_1"));
      }
    },
    toExportDevice: _.debounce(async function () {
      this.selectDeviceList = this.$refs.personalDeviceTable.selection;
      if (this.selectDeviceList.length > 0) {
        for (let i in this.selectDeviceList) {
          let file_data_u8 = await getObjectDataFn(
            this.selectDeviceList[i].file_url
          );
          downloadFileData(file_data_u8, this.selectDeviceList[i].name, "gds");
        }
      } else {
        this.$message.error(this.$t("messages.error_1"));
      }
    }, 500),
    toUploadDevice(row) {
      this.deviceForm = {
        name: row.name,
        label: row.label,
        note: row.note,
        snow_id: row.snow_id,
      };
      this.uploadDeviceVisible = true;
    },
    toUploadSymbol(row) {
      this.deviceForm = {
        name: row.name,
        label: row.label,
        note: row.note,
        snow_id: row.snow_id,
      };
      this.uploadSymbolVisible = true;
    },
    submitDelDevice() {
      const device_snow_ids = this.selectDeviceList.map((item) => {
        return item.snow_id;
      });
      const params = {
        component_type: 1,
        snow_id: device_snow_ids,
        team_id: "",
      };
      this.delDeviceLoading = true;
      deleteDevice(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.delDeviceVisible = false;
          this.selectDeviceDelete = false;
          let deleteIndex = [];
          for (let j in device_snow_ids) {
            for (let i in this.personalDeviceTableData) {
              if (
                device_snow_ids[j] === this.personalDeviceTableData[i].snow_id
              ) {
                deleteIndex.push(i);
              }
            }
          }
          for (let i = deleteIndex.length - 1; i >= 0; i--) {
            this.personalDeviceTableData.splice(deleteIndex[i], 1);
          }
          this.total -= deleteIndex.length;
          if (this.total > 0 && this.personalDeviceTableData.length == 0) {
            this.currentPage = 1;
            this.getDeviceListData();
          }
        } else {
          this.$message.error(res.message);
        }
        this.delDeviceLoading = false;
      });
    },
    submitDelSymbol() {
      const device_snow_ids = this.selectDeviceList.map((item) => {
        return item.snow_id;
      });
      const params = {
        symbol_group: 1,
        snow_ids: device_snow_ids,
        team_id: "",
      };
      this.delSymbolLoading = true;
      deleteSymbol(params).then((res) => {
        if (res.code / 1000 === 200) {
          this.$message.success(this.$t("messages.deleteSuccess"));
          this.delSymbolVisible = false;
          this.selectDeviceDelete = false;
          let deleteIndex = [];
          for (let j in device_snow_ids) {
            for (let i in this.personalSymbolTableData) {
              if (
                device_snow_ids[j] === this.personalSymbolTableData[i].snow_id
              ) {
                deleteIndex.push(i);
              }
            }
          }
          for (let i = deleteIndex.length - 1; i >= 0; i--) {
            this.personalSymbolTableData.splice(deleteIndex[i], 1);
          }
          this.total -= deleteIndex.length;
          if (this.total > 0 && this.personalSymbolTableData.length == 0) {
            this.currentPage = 1;
            this.getSymbolListData();
          }
        } else {
          this.$message.error(res.message);
        }
        this.delSymbolLoading = false;
      });
    },
    addDeviceLabel() {
      if (this.deviceForm.label.length > 15) {
        this.$message.error(this.$t("messages.error_8"));
      } else {
        if (this.deviceForm.label[this.deviceForm.label.length - 1]) {
          this.deviceForm.label.push("");
        } else {
          this.$message.error(this.$t("messages.error_7"));
        }
      }
    },
    delDeviceLabel(index) {
      if (this.deviceForm.label.length < 2) {
        this.$message.error(this.$t("messages.error_6"));
      } else {
        this.deviceForm.label.splice(index, 1);
      }
    },
    searchFileName() {
      const start = (this.fileCurrentPage - 1) * this.filePageSize;
      this.getFilesData(start, this.filePageSize, this.checkProjectGNS);
    },
    searchCellName() {
      const start = (this.fileCurrentPage - 1) * this.filePageSize;
      this.getCellsData(start, this.filePageSize, this.checkProjectGNS);
    },
    toPersonal() {
      this.checkFile = false;
      this.checkCell = false;
      this.personalFileTableData = [];
      this.personalCellTableData = [];
      this.fileCurrentPage = 1;
      this.cellCurrentPage = 1;
    },
    checkFiles(row) {
      setTimeout(() => {
        this.checkFile = true;
        this.checkCell = false;
        this.currentProject = row.name;
        this.checkProjectGNS = row.gns;
        this.fileCurrentPage = 1;
        const start = (this.fileCurrentPage - 1) * this.filePageSize;
        this.getFilesData(start, this.filePageSize, this.checkProjectGNS);
      }, 100);
    },
    checkCells(row) {
      setTimeout(() => {
        this.checkFile = false;
        this.checkCell = true;
        this.currentFile = row.name;
        this.checkProjectGNS = row.gns;
        this.fileCurrentPage = 1;
        this.selectTypes = row.types;
        const start = (this.fileCurrentPage - 1) * this.filePageSize;
        this.getCellsData(start, this.filePageSize, this.checkProjectGNS);
      }, 100);
    },
    getFilesData(start, rows, gns) {
      let params = {
        category: "文件",
        category_child: "个人文件",
        start: start,
        rows: rows,
        parent_gns: gns,
        keyword: this.fileSearchName,
      };
      searchFile_api(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.personalFileTableData = res.data.data.map((item) => {
              const ob = { selected: true };
              return Object.assign(item._source, ob);
            });
            this.fileTotal = res.data.total;
          }
        })
        .catch(() => {
          this.personalFileTableData = [];
        });
    },
    getCellsData(start, rows, gns) {
      let params = {
        category: "CELL",
        category_child: "个人cell",
        start: start,
        rows: rows,
        parent_gns: gns,
        keyword: this.cellSearchName,
        types: null,
      };
      searchFile_api(params)
        .then((res) => {
          if (res.code / 1000 === 200) {
            this.personalCellTableData = res.data.data.map((item) => {
              const ob = { selected: true };
              return Object.assign(item._source, ob);
            });
            this.cellTotal = res.data.total;
          }
        })
        .catch(() => {
          this.personalCellTableData = [];
        });
    },
    async getTeamProjectData(node, data) {
      if (!data.value) return;
      if (node.level > 1) return;
      let res = await getTeamProjectList_api({
        snow_team_id: data.snow_id,
        start: 0,
        rows: 50,
        key_words: "",
        team_gns: data.gns,
      });
      if (res.code == 200000) {
        data.children = res.data.data.map((item) => {
          return { label: item._source.name, value: item._source.gns };
        });
      }
    },
    async submitCreateLibrary() {
      let res = null;
      if (this.clFocus) {
        res = await createLibrary({ name: this.libraryName });
      } else {
        res = await createSymbol({ name: this.libraryName });
      }

      if (res.code == 200000) {
        this.createNewLibraryVisible = false;
        this.$message.success(this.$t("messages.Success"));
        setTimeout(() => {
          this.getLibraryInfo();
        }, 500);
        this.libraryName = "";
      }
    },
    async submitUpdateLibrary() {
      let res = null;
      let params = {
        name: this.libraryName,
        library_snow_id: this.select_component_obj.snow_id,
      };
      if (this.clFocus) {
        res = await updateLibraryName(params);
      } else {
        res = await updateSymbolName(params);
      }

      if (res.code == 200000) {
        this.updateLibraryVisible = false;
        this.$message.success(this.$t("messages.Success"));
        setTimeout(() => {
          this.getLibraryInfo();
        }, 500);
      }
    },
    async getLibraryInfo() {
      this.libraryList = [];
      let res = null;
      if (this.clFocus) {
        res = await getLibraryList();
      } else {
        res = await getSymbolList();
      }
      if (res.code == 200000) {
        this.libraryList = res.data.data.map((item) => {
          const ob = { checked: false };
          return Object.assign(item, ob);
        });
        this.all_libraryOptions = res.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
        this.select_component_obj = this.libraryList[0];
        this.select_component_index = 0;
      }
    },
    async getAllExportLibraryOptions() {
      // let res_component = await getLibraryList();
      let all_data = [];
      let res_symbol = await getSymbolList();
      if (res_symbol.code == 200000) {
        all_data = res_symbol.data.data.map((item) => {
          const ob = { label: item.name, value: item.snow_id };
          return Object.assign(item, ob);
        });
      }
      return all_data;
    },
    async submitDeleteLibrary() {
      let select_list = [];
      this.delSymbolLoading = true;
      for (let i = 0; i < this.libraryList.length; i++) {
        if (this.libraryList[i].checked) {
          select_list.push(this.libraryList[i].snow_id);
        }
      }
      if (select_list.length == 0) {
        this.$message.error(this.$t("messages.selectLibrary"));
        return;
      }
      let res = null;
      if (this.clFocus) {
        res = await deleteComponentLibrary({
          library_snow_data: select_list,
        });
      } else {
        res = await deleteSymbolLibrary({
          library_snow_data: select_list,
        });
      }
      if (res.code == 200000) {
        this.$message.success(this.$t("messages.deleteSuccess"));
        this.checkLibraryDelete = false;
        this.select_component_obj = this.libraryList[0];
        this.select_component_index = 0;
        setTimeout(() => {
          this.getLibraryInfo();
        }, 500);
        if (this.clFocus) {
          setTimeout(() => {
            this.getDeviceListData();
          }, 500);
        } else {
          setTimeout(() => {
            this.getSymbolListData();
          }, 500);
        }
      }
      this.delSymbolLoading = false;
      this.deleteDeviceChecked = false;
      this.deleteLibraryVisible = false;
    },
    async searchStart() {
      this.teamOptionList = [];
      this.projectOptionList = await this.getAllProjectSelectOptions();
      this.setLibraryOptions(this.select_component_obj.snow_id);
    },
    async exportLibraryStart() {
      this.teamOptionList = [];
      this.projectOptionList = await this.getAllProjectSelectOptions();
      this.symbolOptions = await this.getAllExportLibraryOptions();
      this.setLibraryOptions(this.select_component_obj.snow_id);
    },
    setLibraryOptions(library_id) {
      let index = this.all_libraryOptions.findIndex(
        (option) => option.value == library_id
      );
      this.libraryOptions = deepCopy(this.all_libraryOptions);
      this.libraryOptions.splice(index, 1);
    },
    async getAllProjectSelectOptions() {
      let all_data = [
        {
          value: null,
          label: this.$store.state.username,
          children: [],
        },
      ];
      let personal_res = await getUserProjectList_api({
        start: 0,
        rows: 50,
      });
      if (personal_res.code == 200000) {
        all_data[0].children = personal_res.data.data.map((item) => {
          return {
            value: item._source.snow_id,
            label: item._source.name,
          };
        });
      }
      let team_res = await getTeamList_api({
        start: 0,
        rows: 50,
        key_word: "",
      });
      if (team_res.code == 200000) {
        let team_objs = team_res.data.data;
        for (let i = 0; i < team_objs.length; i++) {
          if (team_objs[i].library_permission !== 1) {
            let team_obj = {
              value: team_objs[i].snow_team_id,
              label: team_objs[i].team_name,
              gns: team_objs[i].gns,
              snow_id: team_objs[i].snow_team_id,
              children: [],
            };
            all_data.push(team_obj);
            this.teamOptionList.push(team_obj);
          }
        }
      }
      return all_data;
    },
    async toSearchComponent() {
      this.searchComponentLoading = true;
      let res = null;
      if (this.search_library_type == 0) {
        if (!this.search_library_id) {
          this.searchComponentLoading = false;
          this.searchComponentTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          cell_component_type:
            this.search_component_type == 0
              ? [1, 2]
              : [this.search_component_type],
          library_snow_id: this.search_library_id,
        };
        res = await getPersonalLibraryComponent(params);
      }
      if (this.search_library_type == 1) {
        if (!this.search_team_id) {
          this.searchComponentLoading = false;
          this.searchComponentTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          cell_component_type:
            this.search_component_type == 0
              ? [1, 2]
              : [this.search_component_type],
          team_id: this.search_team_id,
        };
        res = await getTeamLibraryComponent(params);
      } else if (this.search_library_type == 2) {
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          cell_component_type:
            this.search_component_type == 0
              ? [1, 2]
              : [this.search_component_type],
          search_type: 2,
        };
        res = await getPublicLibraryComponent(params);
      } else if (this.search_library_type == 3) {
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          cell_component_type:
            this.search_component_type == 0
              ? [1, 2]
              : [this.search_component_type],
        };
        res = await getOfficialLibraryComponent(params);
      } else if (this.search_library_type == 4) {
        if (!this.search_project_id) {
          this.searchComponentLoading = false;
          this.searchComponentTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          cell_component_type:
            this.search_component_type == 0
              ? [1, 2]
              : [this.search_component_type],
          team_id: this.search_team_id,
          project_snow_id: this.search_project_id,
        };
        res = await getPDKLibraryComponent(params);
      }
      if (res.code == 200000) {
        this.searchComponentTableData = res.data.data.map((item) => {
          return item._source;
        });
      }
      this.searchComponentLoading = false;
    },
    async toSearchSymbol() {
      this.searchSymbolLoading = true;
      let res = null;
      if (this.search_library_type == 0) {
        if (!this.search_library_id) {
          this.searchSymbolLoading = false;
          this.searchSymbolTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          symbol_type:
            this.search_symbol_type == 0 ? [1, 2] : [this.search_symbol_type],
          library_snow_id: this.search_library_id,
        };
        res = await getPersonalLibrarySymbol(params);
      }
      if (this.search_library_type == 1) {
        if (!this.search_team_id) {
          this.searchSymbolLoading = false;
          this.searchSymbolTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          symbol_type:
            this.search_symbol_type == 0 ? [1, 2] : [this.search_symbol_type],
          team_id: this.search_team_id,
        };
        res = await getTeamLibrarySymbol(params);
      } else if (this.search_library_type == 2) {
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          symbol_type:
            this.search_symbol_type == 0 ? [1, 2] : [this.search_symbol_type],
          search_type: 2,
        };
        res = await getPublicLibrarySymbol(params);
      } else if (this.search_library_type == 3) {
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          symbol_type:
            this.search_symbol_type == 0 ? [1, 2] : [this.search_symbol_type],
        };
        res = await getOfficialLibrarySymbol(params);
      } else if (this.search_library_type == 4) {
        if (!this.search_project_id) {
          this.searchSymbolLoading = false;
          this.searchSymbolTableData = [];
          return;
        }
        let params = {
          start: this.search_start,
          rows: this.search_rows,
          key_words: this.searchComponentName,
          symbol_type:
            this.search_symbol_type == 0 ? [1, 2] : [this.search_symbol_type],
          team_id: this.search_team_id,
          project_snow_id: this.search_project_id,
        };
        res = await getPDKLibrarySymbol(params);
      }
      if (res.code == 200000) {
        this.searchSymbolTableData = res.data.data;
      }
      this.searchSymbolLoading = false;
    },
    submitBatchAddComponent: _.debounce(async function (status, cover) {
      if (this.$refs.searchComponentTable.selection.length == 0) {
        this.$message.error(this.$t("messages.selectNone"));
        return;
      }
      let all_snow_ids = this.$refs.searchComponentTable.selection.map(
        (item) => {
          return item.snow_id;
        }
      );
      this.replaceLoading = true;
      let team_data = {
        team_snow_id: this.search_team_id,
        snow_data: all_snow_ids,
      };
      if (all_snow_ids.length == 0) return;
      let params = {
        snow_data: {
          personal: this.search_library_type == 0 ? all_snow_ids : [],
          team: this.search_library_type == 1 ? team_data : {},
          public: this.search_library_type == 2 ? all_snow_ids : [],
          official: this.search_library_type == 3 ? all_snow_ids : [],
          pdk: this.search_library_type == 4 ? all_snow_ids : [],
        },
        library_snow_id: this.select_component_obj.snow_id,
        is_cover: cover,
      };
      try {
        let res = await batchAddComponent(params);
        if (res.code == 200000) {
          if (
            res.data.data.name_exists &&
            res.data.data.name_exists.length > 0
          ) {
            this.existsCellList = res.data.data.name_exists;
            this.existsCellNameVisible = true;
          } else {
            this.cancelSubmitReplaceCellName();
            this.$refs.searchComponentTable.clearSelection();
            this.$message.success(this.$t("messages.Success"));
          }
          setTimeout(() => {
            this.getDeviceListData();
          }, 500);
        }
      } catch {
        this.cancelSubmitReplaceCellName();
        this.$refs.searchComponentTable.clearSelection();
        this.replaceLoading = false;
      }
      if (status) {
        this.cancelSubmitAdd();
      }
      this.replaceLoading = false;
    }, 500),
    submitBatchAddSymbol: _.debounce(async function (status, cover) {
      if (this.$refs.searchSymbolTable.selection.length == 0) {
        this.$message.error(this.$t("messages.selectNone"));
        return;
      }
      let all_snow_ids = this.$refs.searchSymbolTable.selection.map((item) => {
        return item.snow_id;
      });
      this.replaceLoading = true;
      let team_data = {
        team_snow_id: this.search_team_id,
        snow_data: all_snow_ids,
      };
      if (all_snow_ids.length == 0) return;
      let params = {
        snow_data: {
          personal: this.search_library_type == 0 ? all_snow_ids : [],
          team: this.search_library_type == 1 ? team_data : {},
          public: this.search_library_type == 2 ? all_snow_ids : [],
          official: this.search_library_type == 3 ? all_snow_ids : [],
          pdk: this.search_library_type == 4 ? all_snow_ids : [],
        },
        library_snow_id: this.select_component_obj.snow_id,
        is_cover: cover,
      };
      try {
        let res = await batchAddSymbol(params);
        if (res.code == 200000) {
          if (
            res.data.data.name_exists &&
            res.data.data.name_exists.length > 0
          ) {
            this.existsCellList = res.data.data.name_exists;
            this.existsSymbolNameVisible = true;
          } else {
            this.cancelSubmitReplaceCellName();
            this.$refs.searchSymbolTable.clearSelection();
            this.$message.success(this.$t("messages.Success"));
          }
          setTimeout(() => {
            this.getSymbolListData();
          }, 500);
        }
      } catch {
        this.cancelSubmitReplaceCellName();
        this.$refs.searchSymbolTable.clearSelection();
        this.replaceLoading = false;
      }
      if (status) {
        this.cancelSubmitAdd();
      }
      this.replaceLoading = false;
    }, 500),
    axiosExportLibrary: _.debounce(async function (params) {
      try {
        let res = await batchExportLibrary(params);
        if (res.code == 200000) {
          let time = parseInt(new Date().getTime() / 1000);
          let params = {
            time: time,
            batch_path_data: res.data.data,
          };
          downloadZip(params);
          this.batchExportLibraryVisible = false;
        }
      } catch { }
    }, 500),
    async submitExportLibrary() {
      if (this.export_library_type == 1) {
        if (!this.export_library_id) return;
      } else if (this.export_library_type == 2) {
        if (!this.export_team_id) return;
      } else if (this.export_library_type == 5) {
        if (!this.export_project_id) return;
      }
      let params = {
        library_type: this.export_library_type,
        component_library_snow_id:
          this.export_library_type !== 2 ? this.export_library_id : "",
        symbol_library_snow_id: this.export_symbol_id,
        team_id: this.export_library_type !== 2 ? "" : this.export_team_id,
      };
      this.axiosExportLibrary(params);
    },
    cancelSubmitReplaceCellName() {
      this.existsCellList = [];
      this.existsCellNameVisible = false;
      this.existsSymbolNameVisible = false;
    },
    toDeleteLibrary() {
      let selected_library = this.libraryList.filter(
        (library) => library.checked == true
      );
      if (selected_library.length > 0) {
        this.deleteLibraryVisible = true;
      }
    },
    cancelSubmitAdd() {
      this.searchComponentName = "";
      this.search_component_type = 1;
      this.search_project_id = "";
      this.search_library_type = 0;
      this.search_library_id = "";
      this.search_team_id = "";
      this.searchComponentTableData = [];
      this.searchSymbolTableData = [];
      this.batchAddSymbolVisible = false;
      this.batchAddComponentVisible = false;
    },
  },
  created() {
    this.getData(0, this.currentPage * this.pageSize, this.itemName);
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 1px !important;
}
.info {
  padding: 12px;
}
.p-tabs {
  height: 34px;
  width: 100%;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  background: #fff;
  justify-content: flex-start;
  .active {
    border-bottom: 2px solid #0c7de6;
    color: #0c7de6;
  }
  .p-tabs__item {
    margin: 2px 20px 0px;
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
    color: #333333;
  }
  .p-tabs__item:hover {
    cursor: pointer;
  }
}
.p-body {
  position: relative;
  .p-item {
    padding: 12px 10px;
    width: 100%;
    display: flex;
  }
  .p-table {
    background: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    max-width: 100%;
    position: absolute;
    width: 100%;
  }
  /deep/ .table_header {
    font-size: 13px;
    font-weight: bold !important;
    color: #333333;
    background: #e9eff3;
  }
  .operateIcon {
    height: 16px;
    cursor: pointer;
    margin-left: 5px;
  }
  .disabled {
    cursor: not-allowed !important;
  }
  /deep/ .el-table__cell {
    padding: 6px !important;
    font-size: 13px;
    font-weight: 400;
    color: #333333;
  }
}
.default {
  border: 1px solid #378ddd;
  color: #378ddd;
  background: transparent;
}
.danger {
  border: 1px solid #d44051;
  color: #d44051;
  background: transparent;
}
.fill {
  background: #4385f2;
  border: 1px solid #4385f2;
  color: #fff;
}
.el-button {
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  padding: 8px 26px;
}
.el-button--default {
  background: transparent;
  color: #4385f2;
  border: 1px solid #4385f2;
}
.el-button--cancel {
  background: #b0b0b0;
}
.el-button--sure {
  background: #4385f2;
}
.el-button--delete {
  border: 1px solid #d44051;
  color: #d44051;
}
.el-button--del-cancel {
  border: 1px solid #d44051;
  color: #d44051;
}
.el-button--del-sure {
  border: 1px solid #378ddd;
  color: #378ddd;
}
.main-btn {
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 18px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 400;
  .btn-icon {
    height: 16px;
    position: relative;
    top: 2px;
    margin-right: 5px;
  }
}
.btn-icon {
  height: 16px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.main-input {
  width: 208px;
  text-indent: 10px;
  text-decoration: #378ddd;
  background: #fefefe;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin-right: 10px;
}
.main-input:focus {
  outline: 1px solid #378ddd;
}
.el-pagination {
  padding: 12px 20px 12px 12px;
  display: flex;
  justify-content: right;
}
.selectClass {
  display: inline-block;
  ::v-deep {
    .el-input {
      height: 30px !important;
    }
    .el-input__prefix,
    .el-input__suffix {
      height: 30px;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
      border-radius: 2px;
    }
    .el-input__suffix {
      top: 5px;
    }
    .el-input__icon {
      line-height: inherit;
    }
    .el-input__suffix-inner {
      display: inline-block;
    }
  }
}
.dialog-body {
  padding: 30px;
  .item_title {
    display: inline-block;
    margin-right: 45px;
  }
  .item_class {
    font-size: 13px;
    font-weight: 400;
    color: #0c7de6;
    width: 100%;
    max-height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // text-overflow: ellipsis;
    -webkit-line-clamp: 4; /*限制行数为4行*/
    line-clamp: 4; /*限制行数为4行*/
  }
  .selectbtype {
    position: relative;
  }
  /deep/.el-input--suffix .el-input__inner {
    padding-left: 30px;
  }
  .export-group {
    display: flex;
    padding: 5px;
    font-size: 13px;
    color: #333333;
    .group-txt {
      width: 20%;
      position: relative;
      top: 6px;
    }
  }
}
.dialog-body2 {
  padding: 30px;
  .item_title {
    display: inline-block;
    margin-right: 45px;
  }
  .item_class {
    font-size: 13px;
    font-weight: 400;
    color: #0c7de6;
    width: 100%;
    max-height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    // text-overflow: ellipsis;
    -webkit-line-clamp: 4; /*限制行数为4行*/
    line-clamp: 4; /*限制行数为4行*/
  }
  .selectbtype {
    position: relative;
  }
  /deep/ .el-input__count-inner {
    background: transparent;
  }
  /deep/ .el-input__count {
    background: transparent;
  }
  /deep/ .el-textarea__inner {
    background: #f4f4f4 !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
  }
}
.urlInfo {
  font-size: 13px;
  padding: 10px;
  display: inline-block;
}
.copyUrlInfo {
  display: inline-block;
  cursor: pointer;
  font-size: 20px;
}
.confirmItem {
  position: absolute;
  bottom: 25px;
  left: 40px;
}
.confirmFont {
  font-size: 13px;
  color: #333333;
}
.must::before {
  content: "* ";
  color: #ec3838;
}
.form-label {
  display: flex;
  flex-direction: column;
  .form-labrl__item {
    margin: 5px 0;
  }
}
.addLabel {
  display: inline-block;
  span {
    cursor: pointer;
    color: #4385f2;
    font-size: 20px;
    margin-left: 10px;
  }
}
.delLabel {
  display: inline-block;
  span {
    cursor: pointer;
    color: #d44051;
    font-size: 20px;
    margin-left: 10px;
  }
}
.check-item {
  width: 100%;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  .check-item__title {
    padding: 24px 28px;
    .highlight {
      /deep/ .el-breadcrumb__inner {
        color: #378ddd !important;
      }
    }
  }
  .check-item__header__info {
    padding: 0 28px 24px 28px;
    display: flex;
  }
}
.device-select {
  // position: relative;
  width: 18%;
  margin-right: 10px;
  margin-top: 10px;
  display: inline-block;
  height: calc(100vh - 88px);
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  .device-select__header {
    font-size: 13px;
    border-bottom: 1px solid #dddddd;
    padding: 10px;
    width: 92%;
    .device-select__header--text {
      display: inline-block;
      width: 70%;
      font-weight: bold;
    }
    .device-select__header--btn {
      display: inline-block;
      width: 30%;
      color: #0c7de6;
      cursor: pointer;
    }
  }
  .device-select__btnGroup {
    padding: 10px;
    .el-button {
      // width: 110px;
      text-align: center;
      padding: 8px 16px !important;
    }
    .el-button--delete:hover,
    .el-button--delete:focus {
      background: transparent !important;
    }
    .el-button--del-cancel:hover,
    .el-button--del-cancel:focus {
      background-color: transparent !important;
    }
    .el-button--del-sure:hover,
    .el-button--del-sure:focus {
      background-color: transparent !important;
    }
  }
  .device-select__list {
    max-height: 85%;
    overflow-y: auto;
    .device-select__list--item {
      padding: 10px;
      font-size: 13px;
      overflow-y: hidden;
      .list--item__text {
        display: inline-block;
        width: 90%;
      }
      .list--item__check {
        display: inline-block;
        width: calc(10% - 4px);
        text-align: right;
      }
    }
    .device-select__list--item:hover {
      background: #dce6f8;
      cursor: pointer;
    }
    .checked {
      background: #dce6f8;
      color: #0c7de6;
      border-right: 4px solid #0c7de6;
    }
    .checked-border {
      width: 4px;
      // height: 200px;
      background: #0c7de6;
      display: inline-block;
    }
  }
}
.device-info {
  margin-top: 10px;
  width: 100%;
  display: inline-block;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
  .device-info__header {
    font-size: 13px;
    border-bottom: 1px solid #dddddd;
    padding: 10px;
    width: calc(100% - 20px);
    .device-info__header--text {
      display: inline-block;
      width: 70%;
      font-weight: bold;
    }
    .device-info__header--btn {
      display: inline-block;
      text-align: right;
      width: 30%;
      color: #0c7de6;
      cursor: pointer;
    }
  }
  .p-table-device {
    background: #ffffff;
    width: 100%;
    height: calc(100vh - 238px);
  }
}
.device-flex {
  display: flex;
}
.componentSelect {
  display: inline-block;
  margin-right: 10px;
  margin-top: 2px;
  ::v-deep {
    .el-input {
      height: 30px !important;
    }
    .el-input__prefix,
    .el-input__suffix {
      height: 30px;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      background: transparent !important;
    }
    .el-input__suffix {
      top: 5px;
    }
    .el-input__icon {
      line-height: inherit;
    }
    .el-input__suffix-inner {
      display: inline-block;
    }
  }
}
.dialog-body-search {
  padding: 0px;
  .search-header {
    display: flex;
    padding: 10px;
    .el-input {
      margin-right: 10px;
    }
    /deep/ .el-input__inner {
      height: 30px;
      line-height: 30px;
      background: transparent !important;
      vertical-align: middle;
      border-radius: 4px !important;
    }
    .el-cascader {
      /deep/ .el-input__suffix {
        top: 0px !important;
      }
    }
    .componentSelect {
      margin-top: 0px !important;
    }
  }
  .search-body {
    /deep/ .table_header {
      font-size: 13px;
      font-weight: bold !important;
      color: #333333;
      background: #e9eff3;
      padding: 5px 0;
    }
    /deep/ .el-table__cell {
      font-size: 13px !important;
      padding: 5px 0 !important;
      color: #333333;
    }
  }
}
.btn-iconfont {
  font-size: 17px;
  margin-right: 2px;
}
//错误弹窗
.errWindow {
  /deep/.el-dialog {
    width: 575px;
    min-height: 132px;
    font-size: 14px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }

  /deep/.el-dialog__body {
    padding: 20px 20px 30px 20px;
    font-weight: 400;
  }
  .info {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
  }
  /deep/.el-dialog__footer {
    padding: 10px 20px 29px;
    text-align: left;
  }
  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  /deep/.el-button--primary {
    background-color: #4385f2;
    border-color: #4385f2;
  }
  /deep/.el-button--default {
    background-color: #b0b0b0;
    border-color: #b0b0b0;
    color: #ffffff;
  }
  /deep/.el-button--mini {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>
